import React, { useRef, useEffect } from "react"
import SEO from "./seo"
import Header from "./header"
import { Helmet } from "react-helmet"

const Layout = ({ children }) => {
  const refMain = useRef()

  useEffect(() => {
    refMain.current.classList.remove("invisible")
  }, [])

  return (
    <>
      <Header siteTitle="Wilhelm Reuschling" />
      <Helmet>
        <noscript>
          {"<style>.noscript-visible {visibility: visible;}</style>"}
        </noscript>
        <script
          defer
          id="hassia-consent"
          src="/hassia-consent_wilhelm-reuschling.de_20230801.js"
          data-main-color="#004f99" // Dunkle Farbe wählen, da u.a. Hintergrund für weißen Text
          data-accent-color="#ffda1a" // Hintergrundfarbe des Buttons "Akzeptieren"
          // data-rounded			// Abgerundete Ecken verwenden
          // data-discreet			// Unaufdringlichen Modus verwenden
          data-dialog-delay="1" // Anzeige nach 1 Sekunde (Standard)
        ></script>
      </Helmet>
      <SEO title="Wilhelm Reuschling"></SEO>
      <main
        ref={refMain}
        className="invisible min-h-screen py-16 noscript-visible"
      >
        {children}
      </main>
      <footer></footer>
    </>
  )
}

export default Layout
